<div class="location" id="location">
  <div class="location__container container">
    <h2 class="location__header location__title">Место проведения</h2>
    <div class="location__upBlock">
      <p class="location__address">
        <span> AG Loft </span>
        <br />
        Москва, Варшавское шоссе 33с3
      </p>
      <div class="location__text">
        <p class="location__list">
          <strong> Остановки метро: </strong>
          <br />
          Верхние Котлы <br />
          Нагатинская <br />
          МЦК Верхние Котлы
        </p>
        <p class="location__list">
          <strong> Автобусные остановки: </strong>
          <br />
          МЦК Верхние Котлы <br />
          Даниловский мост <br />
          Верхние Котлы (Павелецкое направление) <br />
          Нагатинский <br />
          Стадион Труд
        </p>
      </div>
    </div>
    <div class="location__group">
      <div class="location__column">
        <mat-tab-group
          [selectedIndex]="activeTab"
          class="location__map-toggles"
          animationDuration="0ms"
          mat-align-tabs="center"
          md-no-ink-bar
        >
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="location__toggle button button--darkmagenta">
                <span class="text"> НА АВТОМОБИЛЕ </span>
              </div>
            </ng-template>
            <div class="location__map" id="map">
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3Aecdc8c7801933a8cadb25df73fc7a10ad8b75cc98019569541c35a86b85a0143&amp;source=constructor"
                width="100%"
                height="545"
                frameborder="0"
              ></iframe>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="location__toggle button button--magenta">
                <span class="text"> ОБЩЕСТВЕННЫМ ТРАНСПОРТОМ </span>
              </div>
            </ng-template>
            <div class="location__map">
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3Af79e1709185c1480558a75d69807892cd1b95c7e8ead8885bac37e564d202aa2&amp;source=constructor"
                width="100%"
                height="545"
                frameborder="0"
              ></iframe>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
