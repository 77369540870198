/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EventSet } from '../models/event-set';
import { EventSetCreate } from '../models/event-set-create';
@Injectable({
  providedIn: 'root',
})
class EventSetService extends __BaseService {
  static readonly eventSetListListPath = '/event_set/list';
  static readonly eventSetListCreatePath = '/event_set/list';
  static readonly eventSetReadPath = '/event_set/{id}';
  static readonly eventSetUpdatePath = '/event_set/{id}';
  static readonly eventSetPartialUpdatePath = '/event_set/{id}';
  static readonly eventSetDeletePath = '/event_set/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EventSetService.EventSetListListParams` containing the following parameters:
   *
   * - `ticket_type`: ticket_type
   *
   * - `themes`: themes
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   *
   * - `events`: events
   */
  eventSetListListResponse(params: EventSetService.EventSetListListParams): __Observable<__StrictHttpResponse<Array<EventSet>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ticketType != null) __params = __params.set('ticket_type', params.ticketType.toString());
    if (params.themes != null) __params = __params.set('themes', params.themes.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.events != null) __params = __params.set('events', params.events.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event_set/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EventSet>>;
      })
    );
  }
  /**
   * @param params The `EventSetService.EventSetListListParams` containing the following parameters:
   *
   * - `ticket_type`: ticket_type
   *
   * - `themes`: themes
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   *
   * - `events`: events
   */
  eventSetListList(params: EventSetService.EventSetListListParams): __Observable<Array<EventSet>> {
    return this.eventSetListListResponse(params).pipe(
      __map(_r => _r.body as Array<EventSet>)
    );
  }

  /**
   * @param data undefined
   */
  eventSetListCreateResponse(data: EventSetCreate): __Observable<__StrictHttpResponse<EventSetCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/event_set/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventSetCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  eventSetListCreate(data: EventSetCreate): __Observable<EventSetCreate> {
    return this.eventSetListCreateResponse(data).pipe(
      __map(_r => _r.body as EventSetCreate)
    );
  }

  /**
   * @param id undefined
   */
  eventSetReadResponse(id: string): __Observable<__StrictHttpResponse<EventSet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event_set/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventSet>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventSetRead(id: string): __Observable<EventSet> {
    return this.eventSetReadResponse(id).pipe(
      __map(_r => _r.body as EventSet)
    );
  }

  /**
   * @param params The `EventSetService.EventSetUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSetUpdateResponse(params: EventSetService.EventSetUpdateParams): __Observable<__StrictHttpResponse<EventSetCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/event_set/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventSetCreate>;
      })
    );
  }
  /**
   * @param params The `EventSetService.EventSetUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSetUpdate(params: EventSetService.EventSetUpdateParams): __Observable<EventSetCreate> {
    return this.eventSetUpdateResponse(params).pipe(
      __map(_r => _r.body as EventSetCreate)
    );
  }

  /**
   * @param params The `EventSetService.EventSetPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSetPartialUpdateResponse(params: EventSetService.EventSetPartialUpdateParams): __Observable<__StrictHttpResponse<EventSetCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/event_set/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventSetCreate>;
      })
    );
  }
  /**
   * @param params The `EventSetService.EventSetPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSetPartialUpdate(params: EventSetService.EventSetPartialUpdateParams): __Observable<EventSetCreate> {
    return this.eventSetPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as EventSetCreate)
    );
  }

  /**
   * @param id undefined
   */
  eventSetDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/event_set/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventSetDelete(id: string): __Observable<null> {
    return this.eventSetDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EventSetService {

  /**
   * Parameters for eventSetListList
   */
  export interface EventSetListListParams {

    /**
     * ticket_type
     */
    ticketType?: string;

    /**
     * themes
     */
    themes?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;

    /**
     * events
     */
    events?: string;
  }

  /**
   * Parameters for eventSetUpdate
   */
  export interface EventSetUpdateParams {
    id: string;
    data: EventSetCreate;
  }

  /**
   * Parameters for eventSetPartialUpdate
   */
  export interface EventSetPartialUpdateParams {
    id: string;
    data: EventSetCreate;
  }
}

export { EventSetService }
