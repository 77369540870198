/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BrandFilter } from '../models/brand-filter';
import { Brand } from '../models/brand';
import { BrandCreateUpdate } from '../models/brand-create-update';
@Injectable({
  providedIn: 'root',
})
class BrandService extends __BaseService {
  static readonly brandFilterListPath = '/brand/filter';
  static readonly brandListListPath = '/brand/list';
  static readonly brandListCreatePath = '/brand/list';
  static readonly brandReadPath = '/brand/{id}';
  static readonly brandUpdatePath = '/brand/{id}';
  static readonly brandPartialUpdatePath = '/brand/{id}';
  static readonly brandDeletePath = '/brand/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BrandService.BrandFilterListParams` containing the following parameters:
   *
   * - `theme`: идентификатор темы, можно через запятую
   *
   * - `search`: search
   *
   * - `participation_theme`: идентификатор темы участия, можно через запятую
   *
   * - `ordering`: Ordering
   *
   * - `is_new`: is_new
   *
   * - `hall_number`: hall_number
   *
   * - `catalog_classificator`: идентификатор категории, можно через запятую
   *
   * - `booth_number`: booth_number
   *
   * - `alphabet`: alphabet
   */
  brandFilterListResponse(params: BrandService.BrandFilterListParams): __Observable<__StrictHttpResponse<BrandFilter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.participationTheme != null) __params = __params.set('participation_theme', params.participationTheme.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.isNew != null) __params = __params.set('is_new', params.isNew.toString());
    if (params.hallNumber != null) __params = __params.set('hall_number', params.hallNumber.toString());
    if (params.catalogClassificator != null) __params = __params.set('catalog_classificator', params.catalogClassificator.toString());
    if (params.boothNumber != null) __params = __params.set('booth_number', params.boothNumber.toString());
    if (params.alphabet != null) __params = __params.set('alphabet', params.alphabet.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/brand/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BrandFilter>;
      })
    );
  }
  /**
   * @param params The `BrandService.BrandFilterListParams` containing the following parameters:
   *
   * - `theme`: идентификатор темы, можно через запятую
   *
   * - `search`: search
   *
   * - `participation_theme`: идентификатор темы участия, можно через запятую
   *
   * - `ordering`: Ordering
   *
   * - `is_new`: is_new
   *
   * - `hall_number`: hall_number
   *
   * - `catalog_classificator`: идентификатор категории, можно через запятую
   *
   * - `booth_number`: booth_number
   *
   * - `alphabet`: alphabet
   */
  brandFilterList(params: BrandService.BrandFilterListParams): __Observable<BrandFilter> {
    return this.brandFilterListResponse(params).pipe(
      __map(_r => _r.body as BrandFilter)
    );
  }

  /**
   * @param params The `BrandService.BrandListListParams` containing the following parameters:
   *
   * - `theme`: идентификатор темы, можно через запятую
   *
   * - `search`: search
   *
   * - `participation_theme`: идентификатор темы участия, можно через запятую
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `is_new`: is_new
   *
   * - `hall_number`: hall_number
   *
   * - `catalog_classificator`: идентификатор категории, можно через запятую
   *
   * - `booth_number`: booth_number
   *
   * - `alphabet`: alphabet
   */
  brandListListResponse(params: BrandService.BrandListListParams): __Observable<__StrictHttpResponse<Array<Brand>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.participationTheme != null) __params = __params.set('participation_theme', params.participationTheme.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.isNew != null) __params = __params.set('is_new', params.isNew.toString());
    if (params.hallNumber != null) __params = __params.set('hall_number', params.hallNumber.toString());
    if (params.catalogClassificator != null) __params = __params.set('catalog_classificator', params.catalogClassificator.toString());
    if (params.boothNumber != null) __params = __params.set('booth_number', params.boothNumber.toString());
    if (params.alphabet != null) __params = __params.set('alphabet', params.alphabet.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/brand/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Brand>>;
      })
    );
  }
  /**
   * @param params The `BrandService.BrandListListParams` containing the following parameters:
   *
   * - `theme`: идентификатор темы, можно через запятую
   *
   * - `search`: search
   *
   * - `participation_theme`: идентификатор темы участия, можно через запятую
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `is_new`: is_new
   *
   * - `hall_number`: hall_number
   *
   * - `catalog_classificator`: идентификатор категории, можно через запятую
   *
   * - `booth_number`: booth_number
   *
   * - `alphabet`: alphabet
   */
  brandListList(params: BrandService.BrandListListParams): __Observable<Array<Brand>> {
    return this.brandListListResponse(params).pipe(
      __map(_r => _r.body as Array<Brand>)
    );
  }

  /**
   * @param data undefined
   */
  brandListCreateResponse(data: BrandCreateUpdate): __Observable<__StrictHttpResponse<BrandCreateUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/brand/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BrandCreateUpdate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  brandListCreate(data: BrandCreateUpdate): __Observable<BrandCreateUpdate> {
    return this.brandListCreateResponse(data).pipe(
      __map(_r => _r.body as BrandCreateUpdate)
    );
  }

  /**
   * @param id undefined
   */
  brandReadResponse(id: string): __Observable<__StrictHttpResponse<Brand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/brand/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Brand>;
      })
    );
  }
  /**
   * @param id undefined
   */
  brandRead(id: string): __Observable<Brand> {
    return this.brandReadResponse(id).pipe(
      __map(_r => _r.body as Brand)
    );
  }

  /**
   * @param params The `BrandService.BrandUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  brandUpdateResponse(params: BrandService.BrandUpdateParams): __Observable<__StrictHttpResponse<BrandCreateUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/brand/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BrandCreateUpdate>;
      })
    );
  }
  /**
   * @param params The `BrandService.BrandUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  brandUpdate(params: BrandService.BrandUpdateParams): __Observable<BrandCreateUpdate> {
    return this.brandUpdateResponse(params).pipe(
      __map(_r => _r.body as BrandCreateUpdate)
    );
  }

  /**
   * @param params The `BrandService.BrandPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  brandPartialUpdateResponse(params: BrandService.BrandPartialUpdateParams): __Observable<__StrictHttpResponse<BrandCreateUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/brand/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BrandCreateUpdate>;
      })
    );
  }
  /**
   * @param params The `BrandService.BrandPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  brandPartialUpdate(params: BrandService.BrandPartialUpdateParams): __Observable<BrandCreateUpdate> {
    return this.brandPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as BrandCreateUpdate)
    );
  }

  /**
   * @param id undefined
   */
  brandDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/brand/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  brandDelete(id: string): __Observable<null> {
    return this.brandDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BrandService {

  /**
   * Parameters for brandFilterList
   */
  export interface BrandFilterListParams {

    /**
     * идентификатор темы, можно через запятую
     */
    theme?: string;

    /**
     * search
     */
    search?: string;

    /**
     * идентификатор темы участия, можно через запятую
     */
    participationTheme?: string;

    /**
     * Ordering
     */
    ordering?: 'name' | '-name';

    /**
     * is_new
     */
    isNew?: string;

    /**
     * hall_number
     */
    hallNumber?: string;

    /**
     * идентификатор категории, можно через запятую
     */
    catalogClassificator?: string;

    /**
     * booth_number
     */
    boothNumber?: string;

    /**
     * alphabet
     */
    alphabet?: string;
  }

  /**
   * Parameters for brandListList
   */
  export interface BrandListListParams {

    /**
     * идентификатор темы, можно через запятую
     */
    theme?: string;

    /**
     * search
     */
    search?: string;

    /**
     * идентификатор темы участия, можно через запятую
     */
    participationTheme?: string;

    /**
     * Ordering
     */
    ordering?: 'name' | '-name';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * is_new
     */
    isNew?: string;

    /**
     * hall_number
     */
    hallNumber?: string;

    /**
     * идентификатор категории, можно через запятую
     */
    catalogClassificator?: string;

    /**
     * booth_number
     */
    boothNumber?: string;

    /**
     * alphabet
     */
    alphabet?: string;
  }

  /**
   * Parameters for brandUpdate
   */
  export interface BrandUpdateParams {
    id: string;
    data: BrandCreateUpdate;
  }

  /**
   * Parameters for brandPartialUpdate
   */
  export interface BrandPartialUpdateParams {
    id: string;
    data: BrandCreateUpdate;
  }
}

export { BrandService }
