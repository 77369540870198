/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EntranceTicket } from '../models/entrance-ticket';
import { Checkin } from '../models/checkin';
import { Ticket } from '../models/ticket';
import { TicketCheckin } from '../models/ticket-checkin';
@Injectable({
  providedIn: 'root',
})
class CheckinService extends __BaseService {
  static readonly checkinEntranceTicketListListPath = '/checkin/entrance_ticket/list';
  static readonly checkinEventCheckinBulkUpdatePath = '/checkin/event/checkin/bulk';
  static readonly checkinEventCheckinListListPath = '/checkin/event/checkin/list';
  static readonly checkinEventCheckinListCreatePath = '/checkin/event/checkin/list';
  static readonly checkinEventListListPath = '/checkin/event/list';
  static readonly checkinEventTicketListListPath = '/checkin/event/ticket/list';
  static readonly checkinTicketCheckinBulkUpdatePath = '/checkin/ticket/checkin/bulk';
  static readonly checkinTicketCheckinListListPath = '/checkin/ticket/checkin/list';
  static readonly checkinTicketListListPath = '/checkin/ticket/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CheckinService.CheckinEntranceTicketListListParams` containing the following parameters:
   *
   * - `ticket_type`: specify ticket types by commas
   *
   * - `date_to`: format  %d.%m.%Y  01.01.2021
   *
   * - `date_from`: format  %d.%m.%Y  01.01.2021
   */
  checkinEntranceTicketListListResponse(params: CheckinService.CheckinEntranceTicketListListParams): __Observable<__StrictHttpResponse<Array<EntranceTicket>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ticketType != null) __params = __params.set('ticket_type', params.ticketType.toString());
    if (params.dateTo != null) __params = __params.set('date_to', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('date_from', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checkin/entrance_ticket/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntranceTicket>>;
      })
    );
  }
  /**
   * @param params The `CheckinService.CheckinEntranceTicketListListParams` containing the following parameters:
   *
   * - `ticket_type`: specify ticket types by commas
   *
   * - `date_to`: format  %d.%m.%Y  01.01.2021
   *
   * - `date_from`: format  %d.%m.%Y  01.01.2021
   */
  checkinEntranceTicketListList(params: CheckinService.CheckinEntranceTicketListListParams): __Observable<Array<EntranceTicket>> {
    return this.checkinEntranceTicketListListResponse(params).pipe(
      __map(_r => _r.body as Array<EntranceTicket>)
    );
  }

  /**
   * @param data undefined
   */
  checkinEventCheckinBulkUpdateResponse(data: Array<Checkin>): __Observable<__StrictHttpResponse<Array<Checkin>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/checkin/event/checkin/bulk`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Checkin>>;
      })
    );
  }
  /**
   * @param data undefined
   */
  checkinEventCheckinBulkUpdate(data: Array<Checkin>): __Observable<Array<Checkin>> {
    return this.checkinEventCheckinBulkUpdateResponse(data).pipe(
      __map(_r => _r.body as Array<Checkin>)
    );
  }

  /**
   * @param params The `CheckinService.CheckinEventCheckinListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `date_start`: format  iso  ex: 2022-10-17T11:27:40+03:00
   *
   * - `date_finish`: format  iso  ex: 2022-10-17T11:27:40+03:00
   */
  checkinEventCheckinListListResponse(params: CheckinService.CheckinEventCheckinListListParams): __Observable<__StrictHttpResponse<EntranceTicket>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.dateStart != null) __params = __params.set('date_start', params.dateStart.toString());
    if (params.dateFinish != null) __params = __params.set('date_finish', params.dateFinish.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checkin/event/checkin/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntranceTicket>;
      })
    );
  }
  /**
   * @param params The `CheckinService.CheckinEventCheckinListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `date_start`: format  iso  ex: 2022-10-17T11:27:40+03:00
   *
   * - `date_finish`: format  iso  ex: 2022-10-17T11:27:40+03:00
   */
  checkinEventCheckinListList(params: CheckinService.CheckinEventCheckinListListParams): __Observable<EntranceTicket> {
    return this.checkinEventCheckinListListResponse(params).pipe(
      __map(_r => _r.body as EntranceTicket)
    );
  }

  /**
   * @param data undefined
   */
  checkinEventCheckinListCreateResponse(data: Checkin): __Observable<__StrictHttpResponse<Checkin>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/checkin/event/checkin/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Checkin>;
      })
    );
  }
  /**
   * @param data undefined
   */
  checkinEventCheckinListCreate(data: Checkin): __Observable<Checkin> {
    return this.checkinEventCheckinListCreateResponse(data).pipe(
      __map(_r => _r.body as Checkin)
    );
  }

  /**
   * @param theme specify themes by commas
   */
  checkinEventListListResponse(theme?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (theme != null) __params = __params.set('theme', theme.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checkin/event/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param theme specify themes by commas
   */
  checkinEventListList(theme?: string): __Observable<null> {
    return this.checkinEventListListResponse(theme).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param event event id
   */
  checkinEventTicketListListResponse(event?: number): __Observable<__StrictHttpResponse<Ticket>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (event != null) __params = __params.set('event', event.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checkin/event/ticket/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ticket>;
      })
    );
  }
  /**
   * @param event event id
   */
  checkinEventTicketListList(event?: number): __Observable<Ticket> {
    return this.checkinEventTicketListListResponse(event).pipe(
      __map(_r => _r.body as Ticket)
    );
  }

  /**
   * @param data undefined
   */
  checkinTicketCheckinBulkUpdateResponse(data: Array<TicketCheckin>): __Observable<__StrictHttpResponse<Array<TicketCheckin>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/checkin/ticket/checkin/bulk`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TicketCheckin>>;
      })
    );
  }
  /**
   * @param data undefined
   */
  checkinTicketCheckinBulkUpdate(data: Array<TicketCheckin>): __Observable<Array<TicketCheckin>> {
    return this.checkinTicketCheckinBulkUpdateResponse(data).pipe(
      __map(_r => _r.body as Array<TicketCheckin>)
    );
  }

  /**
   * @param params The `CheckinService.CheckinTicketCheckinListListParams` containing the following parameters:
   *
   * - `ticket_type`: ticket type id
   *
   * - `date_start`: format  iso  ex: 2022-10-17T11:27:40+03:00
   *
   * - `date_finish`: format  iso  ex: 2022-10-17T11:27:40+03:00
   */
  checkinTicketCheckinListListResponse(params: CheckinService.CheckinTicketCheckinListListParams): __Observable<__StrictHttpResponse<TicketCheckin>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ticketType != null) __params = __params.set('ticket_type', params.ticketType.toString());
    if (params.dateStart != null) __params = __params.set('date_start', params.dateStart.toString());
    if (params.dateFinish != null) __params = __params.set('date_finish', params.dateFinish.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checkin/ticket/checkin/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketCheckin>;
      })
    );
  }
  /**
   * @param params The `CheckinService.CheckinTicketCheckinListListParams` containing the following parameters:
   *
   * - `ticket_type`: ticket type id
   *
   * - `date_start`: format  iso  ex: 2022-10-17T11:27:40+03:00
   *
   * - `date_finish`: format  iso  ex: 2022-10-17T11:27:40+03:00
   */
  checkinTicketCheckinListList(params: CheckinService.CheckinTicketCheckinListListParams): __Observable<TicketCheckin> {
    return this.checkinTicketCheckinListListResponse(params).pipe(
      __map(_r => _r.body as TicketCheckin)
    );
  }

  /**
   * @param ticket_type ticket type id
   */
  checkinTicketListListResponse(ticketType?: number): __Observable<__StrictHttpResponse<Ticket>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ticketType != null) __params = __params.set('ticket_type', ticketType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/checkin/ticket/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ticket>;
      })
    );
  }
  /**
   * @param ticket_type ticket type id
   */
  checkinTicketListList(ticketType?: number): __Observable<Ticket> {
    return this.checkinTicketListListResponse(ticketType).pipe(
      __map(_r => _r.body as Ticket)
    );
  }
}

module CheckinService {

  /**
   * Parameters for checkinEntranceTicketListList
   */
  export interface CheckinEntranceTicketListListParams {

    /**
     * specify ticket types by commas
     */
    ticketType?: string;

    /**
     * format  %d.%m.%Y  01.01.2021
     */
    dateTo?: string;

    /**
     * format  %d.%m.%Y  01.01.2021
     */
    dateFrom?: string;
  }

  /**
   * Parameters for checkinEventCheckinListList
   */
  export interface CheckinEventCheckinListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * format  iso  ex: 2022-10-17T11:27:40+03:00
     */
    dateStart?: string;

    /**
     * format  iso  ex: 2022-10-17T11:27:40+03:00
     */
    dateFinish?: string;
  }

  /**
   * Parameters for checkinTicketCheckinListList
   */
  export interface CheckinTicketCheckinListListParams {

    /**
     * ticket type id
     */
    ticketType?: number;

    /**
     * format  iso  ex: 2022-10-17T11:27:40+03:00
     */
    dateStart?: string;

    /**
     * format  iso  ex: 2022-10-17T11:27:40+03:00
     */
    dateFinish?: string;
  }
}

export { CheckinService }
