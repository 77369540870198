/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Search } from '../models/search';
@Injectable({
  providedIn: 'root',
})
class SearchService extends __BaseService {
  static readonly searchListPath = '/search';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SearchService.SearchListParams` containing the following parameters:
   *
   * - `theme`: Идентификаторы тематик
   *
   * - `s`: Search input
   */
  searchListResponse(params: SearchService.SearchListParams): __Observable<__StrictHttpResponse<Search>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.s != null) __params = __params.set('s', params.s.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Search>;
      })
    );
  }
  /**
   * @param params The `SearchService.SearchListParams` containing the following parameters:
   *
   * - `theme`: Идентификаторы тематик
   *
   * - `s`: Search input
   */
  searchList(params: SearchService.SearchListParams): __Observable<Search> {
    return this.searchListResponse(params).pipe(
      __map(_r => _r.body as Search)
    );
  }
}

module SearchService {

  /**
   * Parameters for searchList
   */
  export interface SearchListParams {

    /**
     * Идентификаторы тематик
     */
    theme?: string;

    /**
     * Search input
     */
    s?: string;
  }
}

export { SearchService }
