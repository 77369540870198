import { Component } from '@angular/core';

@Component({
  selector: 'app-ticket-price',
  templateUrl: './ticket-price.component.html',
  styleUrls: ['./ticket-price.component.scss'],
})
export class TicketPriceComponent {
  prices = [
    { price: 'price1', date: 'до 25  ноября' },
    { price: 'price2', date: 'С 26  ноября по 25 декабря ' },
    { price: 'price3', date: 'С 26  декабря  по 25 января' },
    { price: 'price4', date: 'С 26  января  по 25 февраля ' },
    { price: 'price5', date: 'С 26 февраля  по 25 марта' },
    { price: 'price6', date: 'С 26 марта по 30 апреля ' }
  ];
}
