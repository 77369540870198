/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CatalogCreate } from '../models/catalog-create';
@Injectable({
  providedIn: 'root',
})
class CatalogService extends __BaseService {
  static readonly catalogListListPath = '/catalog/list';
  static readonly catalogListCreatePath = '/catalog/list';
  static readonly catalogReadPath = '/catalog/{id}';
  static readonly catalogUpdatePath = '/catalog/{id}';
  static readonly catalogPartialUpdatePath = '/catalog/{id}';
  static readonly catalogDeletePath = '/catalog/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CatalogService.CatalogListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  catalogListListResponse(params: CatalogService.CatalogListListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/catalog/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CatalogService.CatalogListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  catalogListList(params: CatalogService.CatalogListListParams): __Observable<null> {
    return this.catalogListListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   */
  catalogListCreateResponse(data: CatalogCreate): __Observable<__StrictHttpResponse<CatalogCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/catalog/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CatalogCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  catalogListCreate(data: CatalogCreate): __Observable<CatalogCreate> {
    return this.catalogListCreateResponse(data).pipe(
      __map(_r => _r.body as CatalogCreate)
    );
  }

  /**
   * @param id A unique integer value identifying this catalog.
   */
  catalogReadResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/catalog/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this catalog.
   */
  catalogRead(id: number): __Observable<null> {
    return this.catalogReadResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CatalogService.CatalogUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this catalog.
   *
   * - `data`:
   */
  catalogUpdateResponse(params: CatalogService.CatalogUpdateParams): __Observable<__StrictHttpResponse<CatalogCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/catalog/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CatalogCreate>;
      })
    );
  }
  /**
   * @param params The `CatalogService.CatalogUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this catalog.
   *
   * - `data`:
   */
  catalogUpdate(params: CatalogService.CatalogUpdateParams): __Observable<CatalogCreate> {
    return this.catalogUpdateResponse(params).pipe(
      __map(_r => _r.body as CatalogCreate)
    );
  }

  /**
   * @param params The `CatalogService.CatalogPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this catalog.
   *
   * - `data`:
   */
  catalogPartialUpdateResponse(params: CatalogService.CatalogPartialUpdateParams): __Observable<__StrictHttpResponse<CatalogCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/catalog/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CatalogCreate>;
      })
    );
  }
  /**
   * @param params The `CatalogService.CatalogPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this catalog.
   *
   * - `data`:
   */
  catalogPartialUpdate(params: CatalogService.CatalogPartialUpdateParams): __Observable<CatalogCreate> {
    return this.catalogPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as CatalogCreate)
    );
  }

  /**
   * @param id A unique integer value identifying this catalog.
   */
  catalogDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/catalog/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this catalog.
   */
  catalogDelete(id: number): __Observable<null> {
    return this.catalogDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CatalogService {

  /**
   * Parameters for catalogListList
   */
  export interface CatalogListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for catalogUpdate
   */
  export interface CatalogUpdateParams {

    /**
     * A unique integer value identifying this catalog.
     */
    id: number;
    data: CatalogCreate;
  }

  /**
   * Parameters for catalogPartialUpdate
   */
  export interface CatalogPartialUpdateParams {

    /**
     * A unique integer value identifying this catalog.
     */
    id: number;
    data: CatalogCreate;
  }
}

export { CatalogService }
